<template>
  <div
    v-if="
      appConfig.VueSettingsPreRun.FooterPayShowJCB ||
      appConfig.VueSettingsPreRun.FooterPayShowMasterCard ||
      appConfig.VueSettingsPreRun.FooterPayShowVisa ||
      appConfig.VueSettingsPreRun.FooterPayShowMaestro ||
      appConfig.VueSettingsPreRun.FooterPayShowMir
    "
    class="v-card-payment-text v-mb-xs"
    v-html="translate('footerPage.titlePaymentSystemsLinks')"
  />
  <ul
    v-if="isFooter"
    class="v-icon-payment--list"
  >
    <li
      v-if="appConfig.VueSettingsPreRun.FooterPayShowJCB"
      class="v-icon-payment--item"
    >
      <icon-old-payment-jcb />
    </li>
    <li
      v-if="appConfig.VueSettingsPreRun.FooterPayShowMasterCard"
      class="v-icon-payment--item"
    >
      <icon-old-payment-mastercard />
    </li>
    <li
      v-if="appConfig.VueSettingsPreRun.FooterPayShowVisa"
      class="v-icon-payment--item"
    >
      <icon-old-payment-visa />
    </li>
    <li
      v-if="appConfig.VueSettingsPreRun.FooterPayShowMaestro"
      class="v-icon-payment--item"
    >
      <icon-old-payment-maestro />
    </li>
    <li
      v-if="appConfig.VueSettingsPreRun.FooterPayShowMir"
      class="v-icon-payment--item"
    >
      <icon-old-payment-mir />
    </li>
  </ul>
  <ul
    v-else
    class="v-icon-payment--list v-icon-payment--list-paymentRules"
  >
    <li
      v-if="appConfig.VueSettingsPreRun.FooterPayShowJCB"
      class="v-icon-payment--item"
    >
      <span
        class="v-icon-payment--item-name v-mb-xs"
        v-html="'JCB'"
      />
      <icon-old-payment-jcb />
    </li>
    <li
      v-if="appConfig.VueSettingsPreRun.FooterPayShowMasterCard"
      class="v-icon-payment--item"
    >
      <span
        class="v-icon-payment--item-name v-mb-xs"
        v-html="'MasterCard'"
      />
      <icon-old-payment-mastercard />
    </li>
    <li
      v-if="appConfig.VueSettingsPreRun.FooterPayShowVisa"
      class="v-icon-payment--item"
    >
      <span
        class="v-icon-payment--item-name v-mb-xs"
        v-html="'Visa'"
      />
      <icon-old-payment-visa />
    </li>
    <li
      v-if="appConfig.VueSettingsPreRun.FooterPayShowMaestro"
      class="v-icon-payment--item"
    >
      <span
        class="v-icon-payment--item-name v-mb-xs"
        v-html="'Maestro'"
      />
      <icon-old-payment-maestro />
    </li>
    <li
      v-if="appConfig.VueSettingsPreRun.FooterPayShowMir"
      class="v-icon-payment--item"
    >
      <span
        class="v-icon-payment--item-name v-mb-xs"
        v-html="'МИР'"
      />
      <icon-old-payment-mir />
    </li>
  </ul>
</template>

<script setup lang="ts">
withDefaults(
  defineProps<{
    isFooter?: boolean
  }>(),
  {
    isFooter: true
  }
)

const appConfig = useAppConfig()
const { translate } = useI18nSanitized()
</script>

<style lang="scss">
@use 'assets/mixins';

.v-card-payment-text {
  font-weight: 600;
}
.v-icon-payment {
  &--list {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    flex-direction: row;
    margin: 0;
    padding: 0;
    &-paymentRules {
      gap: 60px;
      padding: 20px 0;

      .v-icon-payment--item {
        display: flex;
        align-items: center;
        flex-direction: column;
        padding: 0;
        margin: 0;
        position: relative;
        flex: 0 0 auto;
        @include mixins.md {
          flex: 0 0 20%;
        }
        @include mixins.xs {
          flex: 0 0 50%;
        }
      }
      svg {
        max-width: 50px;
      }
    }
  }
  &--item {
    flex: 0 0 25%;
    max-width: 25%;
    display: flex;
    padding-right: 16px;
    margin-bottom: 16px;
    &-name {
      display: block;
    }
  }
}
</style>
